import React, {useEffect, useState} from 'react';
import logo from './logo.svg';
import './App.css';
import axios from "axios";

function App() {
  let [dataTest, setTest] = useState<string>('');

  useEffect((): void => {
    axios.get('/api/test').then((response): void => {
      alert('요청 성공');
      setTest(response.data);
    }).catch((error): void => {
      alert('요청 실패');
    }).then(function (): void {
    });
  }, []);

  return (
      <div>
        백엔드에서 가져온 데이터입니다 : {dataTest}
      </div>
  );
}

/*
<div className="App">
  <header className="App-header">
    <img src={logo} className="App-logo" alt="logo" />
    <p>
      Edit <code>src/App.tsx</code> and save to reload.
    </p>
    <a
      className="App-link"
      href="https://reactjs.org"
      target="_blank"
      rel="noopener noreferrer"
    >
      Learn React
    </a>
  </header>
</div>
* */
export default App;
